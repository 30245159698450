import { CertemyEnvironment } from './environment.interface';
import pjson from '../../package.json';

export const environment: CertemyEnvironment = {
  name: 'Staging',
  release: pjson.version,
  production: false,
  mixpanelToken: '7ebbe6200a7b0145381ac73119d78c73',
  stripeKey: 'pk_test_NMuH6XKWKXmgrBkN9BZx0LSJ',
  sentryKey: 'https://961c1eb1883145dbb4167b9671347490@sentry-monitoring.certemy.com/15',
  port: '443',
  portAuthz: '443',
  JWT_EXPIRE_TIME: 120,
  CERTEMY_WEBSITE_URL: 'https://certemy.com',
  enableMFA: false,
  GIT_COMMIT: 'b228d8023c30ae2e9647e1e3e363d2c15d73f520',
  PAYFLOW_URL: 'https://pilot-payflowlink.paypal.com',
  SQUARE_URL: 'https://sandbox.web.squarecdn.com/v1/square.js',
  REFERRAL_URL: 'https://certemy-tracking-qa.surge.sh/scripts/tracking/referral.v1.js',
  useReferral: false,
  exactPaymentPath: 'https://rpm.demo.e-xact.com/payment',
  ePayPaymentPath: 'https://epay2dev.wvsto.com/ePayCustomPage/ePay.aspx',
  showExternalLogin: true,
  googleAuthClientId: '14911028123-2u87vj965mismkje49s6l66d8iudieve.apps.googleusercontent.com',
};
